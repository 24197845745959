@font-face {
  font-family: 'Merge';
  src: url('../assets/fonts/merge-light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Merge';
  src: url('../assets/fonts/merge.woff2') format('woff2');
  font-weight: normal;
}
