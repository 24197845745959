$background: #f9f9f9;
$greyish-brown: #4d4d4d;
$brownish-grey: #717171;
$greyish: #a3a3a2;
$black: #282828;
$black-two: #131313;
$dull-pink: #d482a2;
$mauve: #ab6689;

$font-family: 'Merge', 'Arial', sans-serif;
$unit: 8px;
$padding: $unit * 5;
$mobile-padding: 30px;
$container-width: 1160px + $padding * 2;

$shortest: 150ms;
$shorter: 200ms;
$short: 250ms;
$standard: 300ms;
$medium: 500ms;
$long: 800ms;
$longer: 1000ms;
$longest: 1200ms;

$ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);
